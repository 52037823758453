var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-cy": "hot-communities" } },
    [
      _c(
        "VueSlickCarousel",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "prevArrow",
                fn: function (arrowOption) {
                  return [_c("div", { staticClass: "custom-arrow" })]
                },
              },
              {
                key: "nextArrow",
                fn: function (arrowOption) {
                  return [_c("div", { staticClass: "custom-arrow" })]
                },
              },
            ]),
          },
          "VueSlickCarousel",
          _vm.slickSettings,
          false
        ),
        _vm._l(_vm.communities, function (item) {
          return _c(
            "div",
            { staticClass: "c-communities__tile c-communities__tile--hot" },
            [
              _c("a", { attrs: { href: "/" + item.url } }, [
                item.imgUrl
                  ? _c("img", {
                      staticClass: "c-communities__img",
                      attrs: {
                        src: _vm.$simpleStore.cloudinaryUrl(
                          106,
                          106,
                          "img/communities/" +
                            item.communityId +
                            "/" +
                            item.imgUrl,
                          _vm.$root
                        ),
                        alt: "",
                        width: "106",
                        height: "106",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "c-communities__name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }