<template>
    <div>
        <main class="c-page__main" role="main">
            <div v-if="$root.user === false" class="c-hero c-hero--guest js-join-convo__cta" :style="'background-image:url(\'' + homeBackground + '\')'">
                <div class="o-wrapper">
                    <div class="o-grid">
                        <div class="o-grid__item u-width-4/12 u-width-8/12@m u-width-full@s">
                            <h1 class="u-m-b7 u-p-r2 u-m-b3@s">The Home For Your Passions</h1>
                            <div class="u-flex u-flex-align-center">
                                <a v-cloak v-if="$cookies.get('member') === '1'" href="#" class="c-btn u-m-r2" @click.prevent="showLogin">Sign In</a>
                                <a v-cloak v-else href="#" class="c-btn u-m-r2" @click.prevent="showSignup">Join Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="c-hero c-hero--multi">
                <article v-for="banner in banners">
                    <figure>
                        <img :src="$root.cloudinaryUrl + 'f_auto,dpr_' + $root.$dpr + ',q_auto' + banner.image_url" alt="banner">
                    </figure>
                    <div class="o-wrapper">
                        <div class="o-grid">
                            <div class="o-grid__item u-width-7/12 u-width-3/4@l u-width-3/4@m u-width-7/12@s">
                                <div class="c-hero__community">
                                    <a :href="'/' + banner.community_url" class="c-communities__tile c-communities__tile--banner"><img :src="$root.cloudinaryUrl + 'c_fill,f_auto,g_center,h_24,dpr_' + $root.$dpr + ',q_auto,w_24/v1' + banner.community_img" class="c-communities__img">
                                        <div class="c-communities__name">{{ banner.community_name }}</div>
                                    </a>
                                </div>
                                <h1 class="u-color-white u-m-b7 u-p-r2 u-m-b5@l u-m-b2@m u-m-b1@s">{{ banner.title }}</h1>
                                <div class="u-flex u-flex-align-center">
                                    <a :href="banner.call_to_action_url" class="c-btn u-m-r2">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <div class="c-communities__tiles">
                <div class="o-wrapper u-m-t4">
                    <div class="o-grid">
                        <div class="o-grid__item u-width-full">
                            <header class="c-paging__top u-m-b4">
                                <h4><a href="/hot">Hot Communities</a></h4>
                                <a href="/hot" class="c-btn3">More</a>
                            </header>
                            <div class="u-border-b u-p-b2 u-m-b3 u-p-b1@s u-m-b2@s">
                                <router-view name="hotCommunities"></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-wrapper">
                <div class="o-grid">
                    <div class="o-grid__item u-width-full">
                        <router-view name="homeTabs" :joinedcommunities="communityMember"></router-view>
                    </div>
                </div>
            </div>
            <a v-if="$root.user !== false" href="/post/create/" class="c-article__add"></a>
            <a v-else @click.prevent="showMemberPanel" class="c-article__add"></a>
        </main>
        <modal></modal>
        <preferences-modal :channel="registration_channel"></preferences-modal>
    </div>
</template>

<script>
import Modal from "../Modal.vue";
import PreferencesModal from "../PreferencesModal.vue";

export default {
    components: {
        Modal,
        PreferencesModal
    },
    data() {
        return {
            banners: this.$root.banners,
            communityMember: this.$root.communityMember,
            registration_channel: typeof this.$root.registration_channel != 'undefined' ? this.$root.registration_channel : null,
            tour: [
                {
                    target: 'communities',
                    header: {
                        title: 'Get Started',
                    },
                    content: `View list of communities`,
                    crossDevice: false
                },
                {
                    target: 'profile',
                    content: `View your Profile`,
                    crossDevice: false
                },
                {
                    target: 'notifications',
                    content: `Check your notifications`,
                    crossDevice: false
                },
                {
                    target: 'search',
                    content: `Search for communities,<br>content and people`,
                    crossDevice: false
                    /*params: {
                        // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        placement: 'left'
                    }*/
                },
                {
                    target: 'my-feed',
                    content: `View latest posts from<br><a href="/u/` + this.$root.user.username + `" style="text-decoration: underline">your communities</a>`,
                    params: {
                        enableScrolling: false
                    }
                },
                {
                    target: 'explore',
                    content: `Check out the latest posts<br>trending in <a href="/settings/interests" style="text-decoration: underline">your categories</a>`,
                    params: {
                        enableScrolling: false
                    }
                }
            ]
        }
    },
    created() {
        this.$store.commit('startTour', this);
    },
    mounted() {
        if (this.$root.invite != null && this.$root.invite === 'iu') {
            this.$simpleStore.showLoginPanel(this);
        }
        if (this.$root.invite != null && this.$root.invite === 'ig') {
            this.$simpleStore.showSignupPanel(this);
        }
    },
    computed: {
        homeBackground() {
            let mediaSize = this.$simpleStore.getMediaSize();
            let dims = 'w_1440,h_504';
            switch (mediaSize) {
                case 'xl':
                    dims = 'w_1440,h_504';
                    break;
                case 'l':
                    dims = 'w_1020,h_380';
                    break;
                case 'm':
                    dims = 'w_768,h_270';
                    break;
                case 's':
                    dims = 'w_479,h_230';
                    break;
                case 'xs':
                    dims = 'w_375,h_230';
                    break;
            }
            return (this.$root.cloudinaryUrl ? this.$root.cloudinaryUrl + 'f_auto,q_auto,c_fill,' + dims : '') + '/img/hero-home--out.jpg';
        },
        heroDimensions() {
            let mediaSize = this.$simpleStore.getMediaSize();
            console.log('mediaSize:', mediaSize);
            switch (mediaSize) {
                case 'xl':
                    return 'w_1440,h_504';
                case 'l':
                    return 'w_1020,h_380';
                case 'm':
                    return 'w_768,h_270';
                case 's':
                    return 'w_479,h_230';
                case 'xs':
                    return 'w_375,h_230';
            }
        }
    },
    methods: {
        showMemberPanel() {
            this.$simpleStore.showMemberPanel(this);
        },
        showLogin() {
            this.$simpleStore.showLoginPanel(this);
        },
        showSignup() {
            this.$simpleStore.showSignupPanel(this);
        }
    }
}
</script>
