<template>
    <div class="c-cards__wrapper" id="cards" v-cloak>
        <header class="c-paging__top u-m-b4 u-m-b2@s">
            <h4 v-if="$root.user !== false" @click="selectTab('feed')" :class="{ inactive : !feedActive }" data-tour="my-feed">My Feed</h4>
            <h4 @click="selectTab('explore')" :class="{ inactive : !exploreActive }" data-tour="explore">Explore</h4>
        </header>
        <div v-if="$root.user !== false && feedActive" class="c-home-tabs tab-feed">
            <HomeTab name="feed" :active="feedActive" :key="1"></HomeTab>
        </div>
        <div v-if="exploreActive" class="c-home-tabs tab-explore">
            <HomeTab name="explore" :active="exploreActive" :key="2"></HomeTab>
        </div>
    </div>
</template>

<script>
import HomeTab from "./HomeTab.vue";
export default {
    components: {
        HomeTab
    },
    props: ['joinedcommunities'],
    data() {
        return {
            exploreActive: this.$root.user === false || this.joinedcommunities === 0,
            feedActive: this.$root.user !== false && this.joinedcommunities > 0,
            numCommunities: this.joinedcommunities
        }
    },
    created() {
        /*let activeTab;
        if (this.$root.user === false) {
            activeTab = 'explore';
        } else {
            activeTab = sessionStorage.getItem('activeTab');
        }
        if (activeTab != null) {
            this.selectTab(activeTab);
        }*/
    },
    methods: {
        selectTab(tab) {
            if (tab === 'explore') {
                this.exploreActive = true;
                this.feedActive = false;
            } else {
                this.feedActive = true;
                this.exploreActive = false;
            }
            sessionStorage.setItem('activeTab', tab);
        }
    }
}
</script>
