<template>
    <div class="c-cards">
        <div v-if="type === 'feed' && cards.length === 0 && showPlaceholders === false" class="u-width-full">
            <div class="c-empty-state">
                <h4>Become a member of your favorite communities</h4>
                <p>Once you are a member of a community relevant content will appear. Click on the Find a Community icon below to find and then join the communities that interest you.</p>
                <a class="c-empty-state__community_select u-m-t3" href="/communities">
                    <div class="c-communities-select__tile">
                        <img class="c-communities-select__img" src="/img/icons/icon-communities-squared--contained.svg" alt="find communities">
                        <div class="c-communities-select__name"><p>Find a Community</p></div>
                    </div>
                </a>
            </div>
        </div>
        <div class="grid-item"></div>
        <content-placeholders v-if="showPlaceholders"></content-placeholders>
        <post-card-api
            v-for="card in cardsLazy"
            :key="card.id"
            :card="card"
        ></post-card-api>
        <div style="position: absolute; bottom: 0;">&nbsp;</div>
    </div>
</template>

<script>
import PostCardApi from "../PostCardApi.vue";
import MasonryStore from "../../configs/masonry-store";
import ContentPlaceholders from "../ContentPlaceholders";

export default {
    components: {
        PostCardApi,
        ContentPlaceholders
    },
    props: {
        active: Boolean,
        name: String
    },
    data() {
        return {
            cards: [],
            cardsLazy: [],
            masonry: MasonryStore,
            next: true,
            page: 1,
            type: this.name,
            loading: false,
            sessionName: this.name,
            sessionCardIds: this.name.substr(0, 1) + 'Id',
            showPlaceholders: false
        }
    },
    created() {
        // fetch the data when the view is created and the data is
        // already being observed
        sessionStorage.removeItem(this.type + 'Cards');
    },
    mounted() {
        const visibilityRatio = 0.25;
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: visibilityRatio
        }
        let mutationObserver = new MutationObserver((mutations, mObserver) => {
            mutations.forEach(mutation => {
                if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].nodeType === 1 && mutation.addedNodes[0].firstChild.parentElement.className === 'c-card') {
                   // monitor cards scrolling into view
                   let card = mutation.addedNodes[0].firstChild.parentElement; // get an element instead of node
                   let intersectionObserver = new IntersectionObserver((entries, iObserver) => {
                       entries.forEach(entry => {
                           //console.log('entry', entry);
                           if (entry.intersectionRatio >= visibilityRatio) {
                               let sliceStart = this.cardsLazy.length;
                               //console.log('lazy add', sliceStart, sliceStart + 1);
                               this.cardsLazy.push.apply(this.cardsLazy, this.cards.slice(sliceStart, sliceStart + 1));
                               // once scrolled into view do not observe again
                               iObserver.unobserve(entry.target);
                           }
                       });
                   }, observerOptions);
                   intersectionObserver.observe(card);
               }
            });
        });
        // monitor .card being added to .c-cards
        let tab = document.querySelector('.c-home-tabs .c-cards');
        mutationObserver.observe(tab, { childList: true });
        // if initial Explore data already loaded from backend then use it to save a round trip to server
        if (this.name === 'explore' && typeof this.$root.exploreData.cards != 'undefined' && this.$root.exploreData.cards.length > 0) {
            this.cards = this.$root.exploreData.cards;
            this.cardsLazy.push.apply(this.cardsLazy, this.cards.slice(0, 1));
            this.page = 2;
        } else if (this.name === 'feed' && typeof this.$root.myFeedData.cards != 'undefined' && this.$root.myFeedData.cards.length > 0) {
            this.cards = this.$root.myFeedData.cards;
            this.cardsLazy.push.apply(this.cardsLazy, this.cards.slice(0, 1));
            this.page = 2;
        } else {
            this.fetchData('mounted');
        }
        this.scrollPosts();
    },
    updated() {
        //async(onYouTubeIframeAPIReady);
        this.$nextTick(() => {
            this.masonry.load();
        })
    },
    watch: {
        active(newVal, oldVal) {
            if (newVal === true) {
                this.fetchData('activeWatch');
            }
        }
    },
    methods: {
        fetchData(source) {
            if (this.active === false) {
                return false;
            }
            const cardsPerPage = 16;
            let session = this.getSession();
            // if there are valid cards in the session display them and do no requests
            if (this.page === 1 && session[0].cards.length > 0) {
                this.cards = session[0].cards;
                this.cardsLazy.push.apply(this.cardsLazy, this.cards.slice(0, 1));
                this.page = session[0].page;
                this.masonry.images();
                return;
            }
            //console.log(this.cardsLazy.length, this.cardsLazy.length % 16);
            // only make API request for every 16 cards
            if (this.cardsLazy.length > 0 && this.cardsLazy.length % 16 !== 0) {
                return;
            }
            let parameters = {
                page: this.page
            };
            if (this.type === 'feed') {
                parameters.userCommunities = this.$root.user.id;
                parameters.feed = true;
            } else {
                parameters.explore = true;
            }
            //parameters.exclude = sessionStorage.getItem((this.type === 'feed' ? 'e' : 'f') + 'Id');
            this.loading = true;
            if (this.page === 1) {
                this.showPlaceholders = true;
            } else {
                this.showPlaceholders = false;
            }
            this.$http.post('/api/v1/posts', parameters).then(response => {
                if (this.cards.length === 0) {
                    this.cards = response.data.cards;
                } else {
                    this.cards.push.apply(this.cards, response.data.cards);
                }
                let sliceStart = (this.page - 1) * cardsPerPage;
                this.cardsLazy.push.apply(this.cardsLazy, this.cards.slice(sliceStart, sliceStart + 1));
                //console.log('req:', sliceStart, sliceStart + 1, this.cards, this.cardsLazy);
                /*console.log('this.page', this.page);
                console.log('this.cardsLazy', this.cardsLazy);*/
                this.next = this.cards.length > 0;
                this.page++;
                this.loading = false;
                this.showPlaceholders = false;
                //this.storeCardIds(this.name, this.cards);
                this.setSession();
                this.masonry.images();
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        storeCardIds(type, cards) {
            let sessionCards = [];
            if (sessionStorage.getItem(this.sessionCardIds)) {
                sessionCards = JSON.parse(sessionStorage.getItem(this.sessionCardIds));
            }
            for (let i = 0; i < cards.length; i++) {
                let card = cards[i].dyadey_id;
                if (sessionCards.includes(card) === false) {
                    sessionCards.push(card);
                }
            }
            sessionStorage.setItem(this.sessionCardIds, JSON.stringify(sessionCards));
        },
        getSession() {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            let session = sessionStorage.getItem(this.sessionName) ? JSON.parse(sessionStorage.getItem(this.sessionName)) : null;
            let timestamp = session != null ? session[0].timestamp : 0;
            if (timestamp == null || currentTimestamp - timestamp > this.$simpleStore.sessionTTL) {
                sessionStorage.removeItem(this.sessionName);
                return [{
                    cards: [],
                    timestamp: 0,
                    page: 1
                }];
            }
            return session;
        },
        setSession() {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            let session = [{
                cards: [],
                timestamp: 0,
                page: 0
            }];
            if (sessionStorage.getItem(this.sessionName)) {
                session = JSON.parse(sessionStorage.getItem(this.sessionName));
            }
            for (let i = 0; i < this.cards.length; i++) {
                let postCard = {};
                for (let j in this.cards[i]) {
                    if (this.cards[i][j] != null) {
                        postCard[j] = this.cards[i][j];
                    }
                }
                if (session[0].cards == null || session[0].cards.find(x => x.dyadey_id === postCard.dyadey_id) == null) {
                    try {
                        session[0].cards.push(postCard);
                    } catch (err) {
                        console.log('Error:');
                        console.log(err);
                    }
                }
            }
            if (session[0].timestamp === 0) {
                session[0].timestamp = currentTimestamp;
            }
            session[0].page = this.page;
            sessionStorage.setItem(this.sessionName, JSON.stringify(session));
        },
        scrollPosts() {
            window.addEventListener('scroll', () => {
                //let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
                // only calculate scroll height when not loading and when there are more posts to load
                if (this.loading === false && this.next) {
                    let percentageScroll = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) / document.documentElement.offsetHeight;
                    // load more posts when scrolled 90% of screen height
                    if (percentageScroll > 0.9) {
                        this.fetchData('scroll');
                    }
                }
            });
        }
    }
}
</script>
