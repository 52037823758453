<template>
    <div :class="['comments-join-modal', 'c-community__join--modal', { 'open': active }]">
        <div class="o-grid o-grid--center u-bg-white">
            <div class="o-grid__item u-width-full u-a-center u-p-t10 u-p-b5 u-p-x6">
                <img src="/img/ui/cancel.svg" class="c-profile__menu--close" alt="Close Icon" @click="close">
                <div class="c-community__join--community">
                    <img :src="$simpleStore.cloudinaryUrl(106, 106, 'img/communities/1/main.jpg', $root)" alt="Community Logo" height="106" width="106">
                </div>
                <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Welcome</h4>
                <p class="u-m-b3">{{ message }}</p>
                <table class="c-user-categories">
                    <tr v-for="row in categories">
                        <td v-for="(value, category) in row[0]">
                            <label class="radio" data-cy="categories">
                                <input type="checkbox" v-model="chosenCategories" :value="value">
                                <span>{{ category }}</span>
                            </label>
                        </td>
                    </tr>
                </table>
                <button class="c-btn c-btn--red c-btn--join is-outline" @click="close">Close</button>
                <button class="c-btn c-btn--join" @click="savePreferences" data-cy="save-categories">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['channel'],
    data() {
        return {
            active: false,
            chosenCategories: [],
            categories: [
                [{ 'Business': 25, 'Celebrities': 266, 'Fashion': 261 }],
                [{ 'Games': 28, 'Movies': 24, 'Music': 21 }],
                [{ 'Sport': 16, 'Technology': 54, 'Television': 22 }]
            ],
            message: 'We can show you more communities and quality content based on the topics you pick below.'
        }
    },
    created() {
        if (this.$root.showUserCategories === true) {
            this.active = true;
            this.$root.$el.classList.add('faded');
            if (this.channel === 'email') {
                this.message  = 'Thank you for verifying your email - you will shortly receive a welcome email.  ' + this.message;
            } else if (this.channel === 'facebook') {
                this.message  = 'Thank you for registering using Facebook - you will shortly receive a welcome email.  ' + this.message;
            }
        }
    },
    mounted() {

    },
    methods: {
        close(noRequest) {
            this.active = false;
            this.$root.$el.classList.remove('faded');
            if (typeof noRequest != 'undefined' && noRequest === true) {
                return true;
            }
            // send welcome email without setting any preferences
            this.$http.get('/api/v1/no-save-prefs').then(response => {
                console.log('Welcome email sent');
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        savePreferences() {
            let parameters = {
                categories: this.chosenCategories
            };
            this.$http.post('/api/v1/save-prefs', parameters).then(response => {
                if (response.data.success === true) {
                    sessionStorage.removeItem('explore');
                    this.close(true);
                    window.location = '/';
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        }
    }
}
</script>
