var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-cards__wrapper", attrs: { id: "cards" } },
    [
      _c("header", { staticClass: "c-paging__top u-m-b4 u-m-b2@s" }, [
        _vm.$root.user !== false
          ? _c(
              "h4",
              {
                class: { inactive: !_vm.feedActive },
                attrs: { "data-tour": "my-feed" },
                on: {
                  click: function ($event) {
                    return _vm.selectTab("feed")
                  },
                },
              },
              [_vm._v("My Feed")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "h4",
          {
            class: { inactive: !_vm.exploreActive },
            attrs: { "data-tour": "explore" },
            on: {
              click: function ($event) {
                return _vm.selectTab("explore")
              },
            },
          },
          [_vm._v("Explore")]
        ),
      ]),
      _vm._v(" "),
      _vm.$root.user !== false && _vm.feedActive
        ? _c(
            "div",
            { staticClass: "c-home-tabs tab-feed" },
            [
              _c("HomeTab", {
                key: 1,
                attrs: { name: "feed", active: _vm.feedActive },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.exploreActive
        ? _c(
            "div",
            { staticClass: "c-home-tabs tab-explore" },
            [
              _c("HomeTab", {
                key: 2,
                attrs: { name: "explore", active: _vm.exploreActive },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }