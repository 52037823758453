var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main", { staticClass: "c-page__main", attrs: { role: "main" } }, [
        _vm.$root.user === false
          ? _c(
              "div",
              {
                staticClass: "c-hero c-hero--guest js-join-convo__cta",
                style: "background-image:url('" + _vm.homeBackground + "')",
              },
              [
                _c("div", { staticClass: "o-wrapper" }, [
                  _c("div", { staticClass: "o-grid" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "o-grid__item u-width-4/12 u-width-8/12@m u-width-full@s",
                      },
                      [
                        _c("h1", { staticClass: "u-m-b7 u-p-r2 u-m-b3@s" }, [
                          _vm._v("The Home For Your Passions"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "u-flex u-flex-align-center" },
                          [
                            _vm.$cookies.get("member") === "1"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "c-btn u-m-r2",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showLogin.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Sign In")]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "c-btn u-m-r2",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showSignup.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Join Now")]
                                ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _c(
              "div",
              { staticClass: "c-hero c-hero--multi" },
              _vm._l(_vm.banners, function (banner) {
                return _c("article", [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.$root.cloudinaryUrl +
                          "f_auto,dpr_" +
                          _vm.$root.$dpr +
                          ",q_auto" +
                          banner.image_url,
                        alt: "banner",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "o-wrapper" }, [
                    _c("div", { staticClass: "o-grid" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "o-grid__item u-width-7/12 u-width-3/4@l u-width-3/4@m u-width-7/12@s",
                        },
                        [
                          _c("div", { staticClass: "c-hero__community" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "c-communities__tile c-communities__tile--banner",
                                attrs: { href: "/" + banner.community_url },
                              },
                              [
                                _c("img", {
                                  staticClass: "c-communities__img",
                                  attrs: {
                                    src:
                                      _vm.$root.cloudinaryUrl +
                                      "c_fill,f_auto,g_center,h_24,dpr_" +
                                      _vm.$root.$dpr +
                                      ",q_auto,w_24/v1" +
                                      banner.community_img,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "c-communities__name" },
                                  [_vm._v(_vm._s(banner.community_name))]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "h1",
                            {
                              staticClass:
                                "u-color-white u-m-b7 u-p-r2 u-m-b5@l u-m-b2@m u-m-b1@s",
                            },
                            [_vm._v(_vm._s(banner.title))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "u-flex u-flex-align-center" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "c-btn u-m-r2",
                                  attrs: { href: banner.call_to_action_url },
                                },
                                [_vm._v("Read more")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              }),
              0
            ),
        _vm._v(" "),
        _c("div", { staticClass: "c-communities__tiles" }, [
          _c("div", { staticClass: "o-wrapper u-m-t4" }, [
            _c("div", { staticClass: "o-grid" }, [
              _c("div", { staticClass: "o-grid__item u-width-full" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "u-border-b u-p-b2 u-m-b3 u-p-b1@s u-m-b2@s" },
                  [_c("router-view", { attrs: { name: "hotCommunities" } })],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "o-wrapper" }, [
          _c("div", { staticClass: "o-grid" }, [
            _c(
              "div",
              { staticClass: "o-grid__item u-width-full" },
              [
                _c("router-view", {
                  attrs: {
                    name: "homeTabs",
                    joinedcommunities: _vm.communityMember,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.$root.user !== false
          ? _c("a", {
              staticClass: "c-article__add",
              attrs: { href: "/post/create/" },
            })
          : _c("a", {
              staticClass: "c-article__add",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.showMemberPanel.apply(null, arguments)
                },
              },
            }),
      ]),
      _vm._v(" "),
      _c("modal"),
      _vm._v(" "),
      _c("preferences-modal", { attrs: { channel: _vm.registration_channel } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "c-paging__top u-m-b4" }, [
      _c("h4", [
        _c("a", { attrs: { href: "/hot" } }, [_vm._v("Hot Communities")]),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "c-btn3", attrs: { href: "/hot" } }, [
        _vm._v("More"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }