<template>
    <div data-cy="hot-communities">
        <VueSlickCarousel v-bind="slickSettings">
            <div class="c-communities__tile c-communities__tile--hot" v-for="item in communities">
                <a :href="'/' + item.url">
                    <img v-if="item.imgUrl" class="c-communities__img" :src="$simpleStore.cloudinaryUrl(106, 106, 'img/communities/' + item.communityId + '/' + item.imgUrl, $root)" alt="" width="106" height="106">
                    <div class="c-communities__name">{{ item.name }}</div>
                </a>
            </div>
            <template #prevArrow="arrowOption">
                <div class="custom-arrow">
                </div>
            </template>
            <template #nextArrow="arrowOption">
                <div class="custom-arrow">
                </div>
            </template>
        </VueSlickCarousel>
    </div>
</template>

<style>
.slick-prev:before,
.slick-next:before {
    color: black;
}
@media only screen and (max-width: 1020px) {
    .custom-arrow.slick-prev {
        left: -20px;
    }
    .custom-arrow.slick-next {
        right: -20px;
    }
}
</style>
<style scoped>
.slick-slide img {
    display: inline !important;
}
</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            communities: this.$root.hotCommunities,
            slickSettings: {
                'arrows': true,
                'autoplay': false,
                'draggable': false,
                'infinite': true,
                'lazyLoad': 'ondemand',
                'slidesToShow': 10,
                'slidesToScroll': 5,
                'responsive': [
                    {
                        'breakpoint': 1160,
                        'settings': {
                            'arrows': true,
                            'slidesToShow': 8,
                            'slidesToScroll': 4
                        }
                    },
                    {
                        'breakpoint': 980,
                        'settings': {
                            'arrows': false,
                            'slidesToShow': 7,
                            'slidesToScroll': 7
                        }
                    },
                    {
                        'breakpoint': 800,
                        'settings': {
                            'arrows': false,
                            'slidesToShow': 6,
                            'slidesToScroll': 6
                        }
                    },
                    {
                        'breakpoint': 700,
                        'settings': {
                            'arrows': false,
                            'slidesToShow': 5,
                            'slidesToScroll': 5
                        }
                    },
                    {
                        'breakpoint': 600,
                        'settings': {
                            'arrows': false,
                            'slidesToShow': 4,
                            'slidesToScroll': 4
                        }
                    },
                    {
                        'breakpoint': 480,
                        'settings': {
                            'arrows': false,
                            'slidesToShow': 3,
                            'slidesToScroll': 3
                        }
                    }
                ]
            }
        }
    },
    computed: {

    }
}
</script>
